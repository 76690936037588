import { message } from "antd";
import { useRouter } from "next/navigation";
import React from "react";

interface FetchOptions {
  fetchOnLoad?: boolean;
  suppressError?: boolean;
  refreshAfterLoad?: boolean;
  args?: any[];
}

const useFetch = <T>(asyncFunction: (...args: any[]) => Promise<T>, options: FetchOptions | undefined = undefined) => {
  const [data, setData] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const router = useRouter();

  React.useEffect(() => {
    if (options?.fetchOnLoad) {
      if (options?.args) {
        callFetch(...options?.args);
      } else {
        throw new Error("args is required when fetchOnLoad is true");
      }
    }
  }, []);

  async function callFetch(...args) {
    setLoading(true);
    setError(null);
    try {
      const response = await asyncFunction(...args);

      setData(response);
      setLoading(false);

      if (options?.refreshAfterLoad) {
        router.refresh();
      }

      return response;
    } catch (error) {
      if (options?.suppressError !== false) {
        message.error(error.message);
      }
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    error,
    callFetch,
    setData,
  };
};

export default useFetch;
