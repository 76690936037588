import React from "react";
import Link from "next/link";
import { ReactNode } from "react";

interface SearchResultItemProps {
  href: string;
  icon: ReactNode;
  label: string;
  onClick?: () => void;
  isFocused?: boolean;
  subLabel?: string;
}

export function SearchResultItem({ href, icon, label, subLabel, onClick, isFocused }: SearchResultItemProps) {
  return (
    <Link
      href={href}
      className={`flex items-center space-x-3 p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-100 dark:focus:bg-gray-700 ${
        isFocused ? "bg-gray-100 dark:bg-gray-700 ring-2 ring-blue-500" : ""
      }`}
      tabIndex={0}
      onClick={onClick}
    >
      <div className="flex items-center space-x-3">
        {icon}
        <div className="flex flex-col">
          <span>{label}</span>
          {subLabel && <span className="text-gray-500 text-sm">{subLabel}</span>}
        </div>
      </div>
    </Link>
  );
}
